<template>
  <div id="unsubscribe-view">
    <v-container>
      <v-layout row wrap fill-height align-center justify-center>
          <v-flex xs12 class="text-xs-center">
            <h1>Unsubscribed Successfully</h1>
            <p>You will no longer receive these types of emails from Darceo.</p>
          </v-flex>
      </v-layout>
    </v-container>      
  </div>
</template>

<script>
export default {
  data: () => ({

  })
}
</script>

<style scoped>
h1 {
  font-size: 3em;  
}
</style>